<template>
  <div class="account-page">
    <el-main>
      <el-tabs :tab-position="left">
        <el-tab-pane label="Mine detaljer">
          <div class="my-details-container">
            <h2>Mine detaljer</h2>

            <!-- Details section -->
            <div class="section-header">Oplysninger om kontaktperson</div>
            <div class="divider"></div>
            <el-row class="section-row">
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <div class="section-description">
                  Vi bruger disse oplysninger i kommunikationen mellem jeres virksomhed og os. Sørg altid for, at disse oplysninger er korrekte.
                </div>
              </el-col>
              <el-col class="hidden-sm" :xs="2" :sm="2" :md="2" :lg="2" :xl="2"></el-col>
              <el-col :xs="24" :sm="12" :md="9" :lg="9" :xl="9">
                <el-form>
                <div class="section-inputs">  
                  <el-input label="hej" type="text" placeholder="Navn" v-model="userInfo.fullName">
                    <template #prepend>Fulde navn</template>
                  </el-input>
                  <span class="input-error" v-if="errors.showNameError">Feltet skal udfyldes</span>
                  <el-input type="number" placeholder="Telefon nr." v-model="userInfo.phone">
                    <template #prepend>Telefon nr.</template>
                  </el-input>
                  <span class="input-error" v-if="errors.showPhoneError">Feltet skal udfyldes og indeholde 8 tal</span>
                  <el-button :loading="updatingNameAndPhone" @click="updateNameAndPhone" type="success" class="save-changes">Gem ændringer</el-button>
                </div>
                </el-form>
              </el-col>
            </el-row>

            <!-- Details section -->
            <div class="section-header">Email</div>
            <div class="divider"></div>
            <el-row class="section-row">
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <div class="section-description">
                  Vi bruger denne mail for at kunne fremsende fakturaer og evt. kommentarer ifm. jeres ordrer. 
                  Ved accept, kan vi meddele jer omkring nye skærme eller gældende kampagner. 
                </div>
              </el-col>
              <el-col class="hidden-sm" :xs="2" :sm="2" :md="2" :lg="2" :xl="2"></el-col>
              <el-col :xs="24" :sm="12" :md="9" :lg="9" :xl="9">
                <div class="section-inputs">  
                  <el-input type="email" placeholder="Email" v-model="userInfo.email">
                    <template #prepend>Email</template>
                  </el-input>
                  <span class="input-error" v-if="errors.showEmailError">Feltet skal indeholde en gyldig email</span>
                  <div style="width: 100%"><el-checkbox v-model="userInfo.useBillingEmail" /> Brug anden email til fakturering</div>
                  <div v-if="userInfo.useBillingEmail">
                   <el-input type="email" placeholder="Faktura email" v-model="userInfo.billingEmail">
                    <template #prepend>Faktura email</template>
                  </el-input>
                  <span class="input-error" v-if="errors.showBillingEmailError">Feltet skal indeholde en gyldig email</span>
                  </div>
                  <el-button @click="updateEmailAddresses" type="success" class="save-changes">Gem ændringer</el-button>
                </div>
              </el-col>
            </el-row>

            <!-- Details section -->
            <div class="section-header">Virksomhed</div>
            <div class="divider"></div>
            <el-row class="section-row">
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <div class="section-description">
                  Vi anvender udelukkende disse oplysninger til generering af fakturaer, samt til vejledning af skærme I jeres interesse.
                </div>
              </el-col>
              <el-col class="hidden-sm" :xs="2" :sm="2" :md="2" :lg="2" :xl="2"></el-col>
              <el-col :xs="24" :sm="12" :md="9" :lg="9" :xl="9">
                <div class="section-inputs">  
                  
                  <el-input type="text" placeholder="Virksomhedsnavn" v-model="userInfo.companyName">
                    <template #prepend>Navn</template>
                  </el-input>
                  <span class="input-error" v-if="errors.showCompanyError">Feltet skal udfyldes</span>
                  
                  <el-input type="number" placeholder="CVR nr." v-model="userInfo.CVR">
                    <template #prepend>CVR nr.</template>
                  </el-input>
                  <span class="input-error" v-if="errors.showCVRError">Feltet skal udfyldes og indeholde 8 tegn</span>
                  <div class="autocomplete-container">
                   <el-input type="text" placeholder="Vejnavn og hus nr." id="dawa-autocomplete-user-page" v-model="userInfo.streetnameAndNumber">
                    <template #prepend>Adresse</template>
                  </el-input>
                  </div>
                  <span class="input-error" v-if="errors.showStreetnameAndNumberError">Feltet skal udfyldes</span>

                   <el-input type="number" id="zipCode" placeholder="Postnummer" v-model="userInfo.zipCode">
                    <template #prepend>Postnummer</template>
                  </el-input>
                  <span class="input-error" v-if="errors.showZipCodeError">Feltet skal udfyldes og indeholde 4 tegn</span>

                   <el-input type="text" id="city" placeholder="By" v-model="userInfo.city">
                    <template #prepend>By</template>
                  </el-input>
                  <span class="input-error" v-if="errors.ShowCityError">Feltet skal udfyldes</span>
                  
                  <el-button @click="updateCompany" type="success" class="save-changes">Gem ændringer</el-button>
                </div>
              </el-col>
            </el-row>

            <!-- Details section -->
            <div class="section-header">Kodeord</div>
            <div class="divider"></div>
            <el-row class="section-row">
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <div class="section-description">
                  I kan her ændre jeres eksisterende kodeord. Mindst ét stort bogstav og ét tal anbefales. 
                </div>
              </el-col>
              <el-col class="hidden-sm" :xs="2" :sm="2" :md="2" :lg="2" :xl="2"></el-col>
              <el-col :xs="24" :sm="12" :md="9" :lg="9" :xl="9">
                <div class="section-inputs passwords">  
                  <el-input type="password" v-model="userInfo.currentPassword">
                    <template #prepend>Dit kodeord</template>
                  </el-input>
                  <span class="input-error" v-if="errors.showPasswordError">Feltet skal udfyldes</span>
                  <el-input type="password" v-model="userInfo.newPassword">
                    <template #prepend>Nyt kodeord</template>
                  </el-input>
                  <el-input type="password" v-model="userInfo.newPasswordConfirm">
                    <template #prepend>Nyt kodeord igen</template>
                  </el-input>
                  <span class="input-error" v-if="errors.showNewPasswordError">Kodeord skal bestå af samlet 8 tegn og tal</span>
                  <span class="input-error" v-if="errors.showNewPasswordMisMatchError">Kodeord stemmer ikke overens</span>
                  <el-button @click="updatePassword" type="success" class="save-changes">Gem ændringer</el-button>
                </div>
              </el-col>
            </el-row>


          </div>
        </el-tab-pane>

        <el-tab-pane label="Bookings">
          <div class="my-details-container">
            <h2>Bookings</h2>

            <!-- Booking waitning for media -->
            <div class="section-header">Mangler medie</div>
            <div class="divider"></div>

              <div v-if="bookingsWihtMissingMedia.length === 0">I har ingen bookings som mangler medie.</div>

              <div class="booking-card" v-for="bookingItem in bookingsWihtMissingMedia" :key="bookingItem.id">
                <el-row :gutter="24">
                  <el-col :span="5" class="hidden-xs">
                    <img :src="bookingItem.imageUrl" class="booking-card-image" />
                  </el-col>
                  <el-col :xs="12" :sm="10" :md="10" :lg="10" :xl="10">
                    <div class="booking-card-description">
                            <div class="booking-card-title">#{{ bookingItem.invoiceID }} {{ bookingItem.spotTitle }}</div>
                            <div class="booking-card-region-and-city">{{ bookingItem.region }}, {{ bookingItem.city }}</div>
                            <div class="booking-card-address">{{ bookingItem.address }}</div>
                            <div class="booking-card-weekcount">Antal uger: {{ bookingItem.numberOfBookedWeeks }}</div>
                            <div class="booking-card-weekcount">Dage til start: {{ bookingItem.daysToGoLive }}</div>
                            <div class="booking-card-weekcount" v-if="bookingItem.weeks != ''">Uge: {{ bookingItem.weeks }}</div>
                        </div>
                  </el-col>
                  <el-col :xs="12" :sm="9" :md="9" :lg="9" :xl="9">
                    <div class="booking-card-actions">
                      <el-button @click="addMediaToBooking(bookingItem.id, 'Tilføj medie', 'Tilføj den reklame eller media som du ønsker at vise på skærmen.')" class="add-media-btn" icon="el-icon-plus">Tilføj medie</el-button>
                      <el-button v-if="!bookingItem.sentToReview && !bookingItem.missingMedia" @click="sendBookingToReview(bookingItem)" class="send-to-activation-btn" icon="el-icon-check">Send til gennemgang</el-button>
                    </div>
                  </el-col>
                </el-row>
              </div>

          <!-- Booking waiting for activation -->
          <div class="section-header second-section-header">Afventer gennemgang</div>
          <div class="divider"></div>

            <div v-if="bookingsWaitingApproval.length === 0">I har ingen bookings som afventer gennemgang.</div>

            <div class="booking-card" v-for="bookingItem in bookingsWaitingApproval" :key="bookingItem.id">
                <el-row :gutter="24">
                  <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
                    <img :src="bookingItem.imageUrl" class="booking-card-image" />
                  </el-col>
                  <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="10">
                    <div class="booking-card-description">
                            <div class="booking-card-title">#{{ bookingItem.invoiceID }} {{ bookingItem.spotTitle }}</div>
                            <div class="booking-card-region-and-city">{{ bookingItem.region }}, {{ bookingItem.city }}</div>
                            <div class="booking-card-address">{{ bookingItem.address }}</div>
                            <div class="booking-card-weekcount">Antal uger: {{ bookingItem.numberOfBookedWeeks }}</div>
                            <div class="booking-card-weekcount" v-if="bookingItem.daysToGoLive > 0">Dage til start: {{ bookingItem.daysToGoLive }}</div>
                            <div class="booking-card-weekcount" v-if="bookingItem.daysToGoLive <= 0">LIVE</div>
                            <div class="booking-card-weekcount" v-if="bookingItem.weeks != ''">Uge: {{ bookingItem.weeks }}</div>
                        </div>
                  </el-col>
                  <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="8">
                    <div class="receivedMessage" v-if="!bookingItem.hasBeenRejected">Vi har modtaget jeres medie og vi gennemgår i øjeblikket det medie I har uploadet for at sikre, at det overholder vores retningslinjer og betingelser for denne skærm.</div>
                    <div class="rejectedMessage" v-if="bookingItem.hasBeenRejected">
                      <p>Dit uploadet media er ikke blevet godkendt grundet følgende:</p>
                      <p class="rejection-message">{{ bookingItem.rejectionReason }}</p>
                      <p>Du kan herunder rette dine uploaded media, så de opfylder kravende stillet ovenfor.</p>
                      <el-button @click="addMediaToBooking(bookingItem.id, 'Tilføj medie', 'Tilføj den reklame eller media som du ønsker at vise på skærmen.')" class="add-media-btn">Ret medie</el-button>
                      <el-button v-if="!bookingItem.missingMedia" @click="resendBookingToReview(bookingItem)" class="send-to-activation-btn" icon="el-icon-check">Send til gennemgang</el-button>
                    </div>
                </el-col>
              </el-row>
            </div>

          <!-- Booking that are active -->
          <div class="section-header second-section-header">Igangværende</div>
          <div class="divider"></div>

          <div v-if="bookingsActive.length === 0">I har ingen igangværende bookings.</div>

          <div class="booking-card" v-for="bookingItem in bookingsActive" :key="bookingItem.id">
                <el-row :gutter="24">
                  <el-col :span="5" class="hidden-xs">
                    <img :src="bookingItem.imageUrl" class="booking-card-image" />
                  </el-col>
                  <el-col :xs="12" :sm="10" :md="10" :lg="10" :xl="10">
                    <div class="booking-card-description">
                            <div class="booking-card-title">#{{ bookingItem.invoiceID }} {{ bookingItem.spotTitle }}</div>
                            <div class="booking-card-region-and-city">{{ bookingItem.region }}, {{ bookingItem.city }}</div>
                            <div class="booking-card-address">{{ bookingItem.address }}</div>
                            <div class="booking-card-weekcount">Antal uger: {{ bookingItem.numberOfBookedWeeks }}</div>
                        </div>
                  </el-col>
                <el-col :xs="12" :sm="9" :md="9" :lg="9" :xl="9">
                  <div class="booking-card-actions">
                    <div style="width: 100%; height: 50px;">
                      <p v-if="bookingItem.daysToGoLive === 1" style="float: left; height: 100%; line-height: 25px;">{{ bookingItem.daysToGoLive }} dag til start</p>
                      <p v-if="bookingItem.daysToGoLive > 1" style="float: left; height: 100%; line-height: 25px;">{{ bookingItem.daysToGoLive }} dage til start</p>
                      <p v-if="bookingItem.daysToGoLive <= 0" style="float: left; height: 100%; line-height: 25px;">Live på skærm</p>
                      <div style="padding-top: 5px; padding-left: 20px; float: left;">
                        <el-button @click="addMediaToBooking(bookingItem.id, 'Ret/Se medie', 'Ved rettelse af eksiterende medie, vil vi gennemgå dit nye medie i forhold til vores retningslinjer og betingelser for skærmen. For at uploade et nyt medie skal du først slette der eksisterende medie. Efter det nye medie er uploadet, vil det blive sendt til gennemgang, mens det eksisterende medie fortsat vil være at finde på skærmen.')" class="add-media-btn" icon="el-icon-plus">Ret/Se medie</el-button>
                      </div>
                    </div>
                  </div>
               </el-col>
              </el-row>
            </div> 

          <!-- Booking that has been closed -->
          <div class="section-header second-section-header">Afsluttede</div>
          <div class="divider"></div>

           <div v-if="bookingsEnded.length === 0">I har ingen afsluttede bookings.</div>

          <div class="booking-card-finished" v-for="bookingItem in bookingsEnded" :key="bookingItem.id">
              <el-row :gutter="24">
                <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <div class="booking-card-title">#{{ bookingItem.invoiceID }} {{ bookingItem.spotTitle }}</div>
                      <div class="booking-card-region-and-city">{{ bookingItem.region }}, {{ bookingItem.city }}</div>
                      <div class="booking-card-address">{{ bookingItem.address }}</div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <div class="booking-card-description" style="float: right; text-align: right;">
                    <div class="booking-card-finished-weekcount">Antal uger {{ bookingItem.numberOfBookedWeeks }} - Afsluttet d. {{ bookingItem.endDate }}</div>
                  </div>
                </el-col>
              </el-row>
            </div>

          </div>
        </el-tab-pane>

        <el-tab-pane label="Faktura">
          <div class="my-details-container">
            <h2>Faktura</h2>
            
            <!-- Invoice section -->
            <div class="section-header">Faktura(er)</div>
            <div class="divider"></div>
            <el-row :gutter="24" class="invoice-container" v-for="invoice in invoices" :key="invoice.number">
              <el-col :xs="12" :sm="5" :md="5" :lg="5" :xl="5">Købsdato: <span class="font-weight-600">{{invoice.billingDate}}</span></el-col>
              <el-col :xs="12" :sm="5" :md="5" :lg="5" :xl="5">Faktura/Ordre nr: <span class="font-weight-600">#{{invoice.number}}</span></el-col>
              <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">Beløb: <span class="font-weight-600">{{invoice.priceWithVAT}} ,-</span></el-col>
              <el-col :xs="12" :sm="4" :md="4" :lg="4" :xl="4">Antal uger: <span class="font-weight-600">{{invoice.weeks.length}}</span></el-col>
              <el-col :xs="12" :sm="4" :md="4" :lg="4" :xl="4"><el-button :loading="invoice.loading" @click="downloadPDF(invoice.number); invoice.loading = true;" class="add-media-btn" size="medium">Download PDF</el-button></el-col>
            </el-row>


          </div>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </div>

  <!-- add media dialog -->
    <el-dialog 
    :title="addMediaToBookingTitle" 
    v-model="addMediaToBookingDialogVisible" 
    width="400px"
    destroy-on-close
    >
        <div class="add-media-description">
            {{ addMediaToBookingText }}
        </div>

        <el-form :model="addMediaForm" >
            <el-form-item>
                <el-upload
                class="upload-box"
                drag
                :action="`${backendUrl}Booking/AddImageToBooking?bookingID=${addMediaForm.bookingID}`"
                :before-upload="validateUpload"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :file-list="fileList"
                :on-success="uploadSucess"
                :with-credentials="true"
                :headers="userAuth"
                show-file-list="true"
                limit="1"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">Træk og slip dit medie her eller <em>klik for upload</em></div>
                <template #tip>
                  <div class="el-upload__tip">
                    Maksimal tilladte filstørrelse: 20mb og der kan kun uploades én fil.
                  </div>
                </template>
              </el-upload>
            </el-form-item>
            <div class="add-media-actions">
                <el-button style="float: left;" @click="addMediaToBookingDialogVisible = false">Annuller</el-button>
                <el-button style="float: right;" @click="shouldShowSendToActivation" type="success">Færdig</el-button>
            </div>
        </el-form>
    </el-dialog> 

  <!-- Image preview -->
  <el-dialog  
    v-model="showImagePreview" 
    destroy-on-close
    >

        <img v-if="previewMediaType === 'base64'" style="width: 100%" :src="`data:image/png;base64,${previewMedia}`">
        <img v-if="previewMediaType === 'url'" style="width: 100%" :src="previewMedia">
    </el-dialog> 

</template>

<script>
import axios from 'axios';
import $ from 'jquery';

export default {
  name: 'AccountPage',
  data() {
    return {
      userInfo: {
        fullName: '',
        companyName: '',
        CVR: 0,
        streetnameAndNumber: '',
        zipCode: '',
        city: '',
        email: '',
        billingEmail: '',
        phone: 0,
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
        useBillingEmail: false,

      },
      errors: {
        showNameError: false,
        showPhoneError: false,
        showEmailError: false,
        showStreetnameAndNumberError: false,
        showZipCodeError: false,
        ShowCityError: false,
        showBillingEmailError: false,
        showCompanyError: false,
        showCVRError: false,
        showPasswordError: false,
        showNewPasswordError: false,
        showNewPasswordMisMatchError: false 
      },
      addMediaForm: {
        bookingID: 9,
        media: ''
      },
      previewMedia: "",
      previewMediaType: "",
      bookingsWihtMissingMedia: [],
      bookingsWaitingApproval: [],
      bookingsActive: [],
      bookingsEnded: [],
      fileList: [],
      addMediaToBookingDialogVisible: false,
      addMediaToBookingTitle: "",
      addMediaToBookingText: "",
      showImagePreview: false,
      invoices: []
    }
  },
  created() {
    
  },
  computed: {
    backendUrl() {
      return process.env.VUE_APP_BACKEND_URL.toString();
    },
    userAuth() {
      return {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }
  },
  mounted() {
    this.setDisplayValuesForUserInfo();
    this.getBookings();
    this.getInvoices();
    // if(typeof dawaAutocomplete !== undefined) {
    //   console.log("got in: ", typeof dawaAutocomplete);
    //   // eslint-disable-next-line no-undef
    //   dawaAutocomplete.dawaAutocomplete(document.getElementById('dawa-autocomplete-user-page'), {
    //       select: function(selected) {
    //         $('#zipCode').val(selected.data.postnr);
    //         $('#city').val(selected.data.postnrnavn);
    //       }
    //     });
    // }
  },
  methods: {
    setDisplayValuesForUserInfo() {
      var userCookie = JSON.parse(localStorage.getItem("user"));
      this.userInfo.fullName = userCookie.fullName;
      this.userInfo.companyName = userCookie.companyName;
      this.userInfo.CVR = userCookie.cvr;
      this.userInfo.email = userCookie.email;
      this.userInfo.useBillingEmail = userCookie.useBillingEmail;
      this.userInfo.billingEmail = userCookie.emailForBilling;
      this.userInfo.phone = userCookie.phoneNumber;
      this.userInfo.streetnameAndNumber = userCookie.streetnameAndNumber;
      this.userInfo.zipCode = userCookie.zipCode;
      this.userInfo.city = userCookie.city;
    },
    getInvoices() {
      axios.get(`${process.env.VUE_APP_BACKEND_URL}Invoice/GetAllInvoices`)
      .then(response => {
          var data = response.data;
          if(data.success === true)
          {
            this.invoices = data.data;

            for(var index = 0; index < this.invoices.length; index++)
            {
              this.invoices[index].loading = false;
            }
          }
      }).catch(error => {
        console.log(error);
      });
    },
    // eslint-disable-next-line no-unused-vars
    downloadPDF(invoiceID) {
      axios({
        url: `${process.env.VUE_APP_BACKEND_URL}Invoice/DownloadInvoice?invoiceID=${invoiceID}`,
        method: 'GET',
        responseType: 'blob'
        }).then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
      
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `Faktura ordre nr ${invoiceID}.pdf`);
        document.body.appendChild(fileLink);
      
        fileLink.click();

        for(var index = 0; index < this.invoices.length; index++)
        {
          this.invoices[index].loading = false;
        }
      });
    },
    getBookings() {
      axios.get(`${process.env.VUE_APP_BACKEND_URL}Booking/GetAllActiveOnUser`)
          .then(response => {
              var data = response.data;

              if(data.success === true) {
                for(var index = 0; index < data.data.length; index++)
                {
                  var bookingItem = data.data[index];

                  if(bookingItem.hasEnded)
                  {
                    this.bookingsEnded.push(bookingItem);
                    continue;
                  }

                  if(!bookingItem.sentToReview)
                  {
                    this.bookingsWihtMissingMedia.push(bookingItem);
                    continue;
                  }
                  if(bookingItem.sentToReview && !bookingItem.hasBeenApproved)
                  {
                    this.bookingsWaitingApproval.push(bookingItem);
                    continue;
                  }
                  if(bookingItem.hasBeenApproved)
                  {
                    this.bookingsActive.push(bookingItem);
                    continue;
                  }
                }
              }
          }).catch(error => {
                console.log(error);
          })
    },
    setUseBillingEmail() {
      this.useBillingEmail = !this.useBillingEmail;
    },
    validateUpload(file) {
      if(!file.type.toLowerCase().includes('image/') && !file.type.toLowerCase().includes('video/')) {
        this.$message({
          message: 'Du kan kun uploade billed- eller videofiler',
          type: 'warning'
        });
        return false;
      }
      else if(file.size > 20971520) {
        this.$message({
          message: 'Filen må ikke være større end 20MB',
          type: 'warning'
        });
        return false;
      }
      return true;
    },
    addMediaToBooking(bookingID, title, text) {
      // Get images on booking
      this.addMediaForm.bookingID = bookingID;
      axios.get(`${process.env.VUE_APP_BACKEND_URL}Image/GetImagesForBooking?bookingID=` + bookingID)
          .then(response => {
              var data = response.data; 
              if(data.success === true) {
                this.fileList = [];
                for(var index = 0; index < data.data.length; index++)
                {
                  var imageData = data.data[index];
                  var fileInfo = {
                    id: imageData.id,
                    name: imageData.name,
                    base64String: imageData.base64
                  }
                  this.fileList.push(fileInfo);
                }
              }
          }).catch(error => {
                console.log(error);
          })
      // Set FileForm items
      this.addMediaToBookingDialogVisible = true;
      this.addMediaToBookingTitle = title;
      this.addMediaToBookingText = text;
    },
    uploadSucess(response, file) {
      file.id = response.data.id;

      var bookingItemFromMissing = this.bookingsWihtMissingMedia.find(x => x.id === response.data.bookingID);
      var bookingItemFromAwaitingApproval = this.bookingsWaitingApproval.find(x => x.id === response.data.bookingID);
      var bookingItemFromActive = this.bookingsActive.find(x => x.id === response.data.bookingID);


      if(bookingItemFromMissing !== undefined) {
        bookingItemFromMissing.missingMedia = false;
      }
      else if( bookingItemFromAwaitingApproval !== undefined)
      {
        bookingItemFromAwaitingApproval.missingMedia = false;
      }
      else if (bookingItemFromActive !== undefined)
      {
        this.bookingsWaitingApproval.push(bookingItemFromActive);
        this.bookingsActive = this.bookingsActive.filter(x => x.id !== bookingItemFromActive.id);
      }
      
      file.name = response.data.name;
    },
    handleRemove(file, fileList) {
      axios.delete(`${process.env.VUE_APP_BACKEND_URL}Image/deleteFile?imageID=` + file.id)
        .then(response => {
          var data = response.data;

          if(data.success === true)
          {
            if(fileList.length === 0)
            {
              var bookingID = data.data;
              var bookingItemFromMissing = this.bookingsWihtMissingMedia.find(x => x.id === bookingID);
              var bookingItemFromAwaitingApproval = this.bookingsWaitingApproval.find(x => x.id === bookingID);

              if(bookingItemFromMissing !== undefined) {
                bookingItemFromMissing.missingMedia = true;
              }
              else if( bookingItemFromAwaitingApproval !== undefined)
              {
                bookingItemFromAwaitingApproval.missingMedia = true;
              }
            }
          }
        });
    },
    handlePreview(file) {
      if(typeof file === 'string')
      {
        this.previewMedia = file;
        this.previewMediaType = "url";
      }
      else {
        this.previewMediaType = "base64";
        if(file.response !== undefined) {
          this.previewMedia = file.response.data.base64;
        }
        else {
          this.previewMedia = file.base64String;
        }
      }

      this.showImagePreview = true;
    },
    sendBookingToReview(bookingItem) {
      axios.post(`${process.env.VUE_APP_BACKEND_URL}Booking/SendBookingToReview?bookingID=` + bookingItem.id)
          .then(response => {
              var data = response.data; 
              if(data.success === true) {
                this.bookingsWaitingApproval.push(bookingItem);
                this.bookingsWihtMissingMedia = this.bookingsWihtMissingMedia.filter(booking => booking.id !== bookingItem.id);
              }
          }).catch(error => {
                console.log(error);
          })
    },
    resendBookingToReview(bookingItem) {
      axios.post(`${process.env.VUE_APP_BACKEND_URL}Booking/SendBookingToReview?bookingID=` + bookingItem.id)
      .then(response => {
        var data = response.data;
        if(data.success === true)
        {
          bookingItem.hasBeenRejected = false;
        }
      });
    },
    shouldShowSendToActivation() {
      this.addMediaToBookingDialogVisible = false;
    },
    updateNameAndPhone() {
      this.errors.showNameError = false;
      this.errors.showPhoneError = false;
      
      if(this.userInfo.fullName.length === 0) {
        this.errors.showNameError = true;
      }
      if(this.userInfo.phone.toString().length != 8)
      {
        this.errors.showPhoneError = true;
      }

      if(!this.errors.showNameError && !this.errors.showPhoneError) 
      {
        var dataToUpdate = {
          fullName: this.userInfo.fullName,
          phoneNumber: parseInt(this.userInfo.phone)
        };

        this.updateUserInfo(dataToUpdate);
      }
    },
    updateEmailAddresses() {
      this.errors.showEmailError = false;
      this.errors.showBillingEmailError = false;

      if(!this.validateEmail(this.userInfo.email))
      {
        this.errors.showEmailError = true;
      }

      if(this.userInfo.useBillingEmail && !this.validateEmail(this.userInfo.billingEmail))
      {
        this.errors.showBillingEmailError = true;
      }

      if(!this.errors.showEmailError && !this.errors.showBillingEmailError)
      {
        var dataToUpdate = {
          email: this.userInfo.email,
          emailForBilling: this.userInfo.billingEmail,
          useBillingEmail: this.userInfo.useBillingEmail
        };

        this.updateUserInfo(dataToUpdate);
      }
    },
    updateCompany() {
      this.errors.showCompanyError = false;
      this.errors.showCVRError = false;
      this.errors.showStreetnameAndNumberError = false,
      this.errors.showZipCodeError = false;
      this.errors.ShowCityError = false;
      var returnError = false;

      this.userInfo.streetnameAndNumber = $('#dawa-autocomplete-user-page').val();
      this.userInfo.zipCode = $('#zipCode').val();
      this.userInfo.city = $('#city').val();

      if(this.userInfo.companyName.length === 0)
      {
        this.errors.showCompanyError = true;
        returnError = true;
      }

      if(this.userInfo.CVR.toString().length != 8)
      {
        this.errors.showCVRError = true;
        returnError = true;
      }

      if(this.userInfo.streetnameAndNumber.length < 1)
      {
        this.errors.showStreetnameAndNumberError = true;
        returnError = true;
      }

      if(this.userInfo.zipCode.length !== 4)
      {
        this.errors.showZipCodeError = true;
        returnError = true;
      }

      if(this.userInfo.city.length < 1) 
      {
        this.errors.ShowCityError = true;
        returnError = true;
      }


      if(!returnError)
      {
        var dataToUpdate = {
          companyName: this.userInfo.companyName,
          cvr: parseInt(this.userInfo.CVR),
          streetnameAndNumber: this.userInfo.streetnameAndNumber,
          zipCode: this.userInfo.zipCode,
          city: this.userInfo.city
        };

        this.updateUserInfo(dataToUpdate);
      }
    },
    updatePassword() {
      this.errors.showNewPasswordError = false;
      this.errors.showNewPasswordMisMatchError = false;
      this.errors.showPasswordError = false;

      if(this.userInfo.currentPassword.length === 0)
      {
        this.errors.showPasswordError = true;
        return;
      }

      if(this.userInfo.newPassword.length < 8 || this.userInfo.newPasswordConfirm.length < 8 || !this.validatePassword(this.userInfo.newPassword))
      {
        this.errors.showNewPasswordError = true;
        return;
      }

      if(this.userInfo.newPassword !== this.userInfo.newPasswordConfirm)
      {
        this.errors.showNewPasswordMisMatchError = true;
        return;
      }

      var dataToUpdate = {
          currentPassword: this.userInfo.currentPassword,
          newPassword: this.userInfo.newPassword,
          confirmPassword: this.userInfo.newPasswordConfirm
        };

      axios.post(`${process.env.VUE_APP_BACKEND_URL}Auth/UpdatePassword`, dataToUpdate)
          .then(response => {
              var data = response.data; 
              if(data.success)
              {
                this.$message({
                  message: 'Dit kodeord er blevet opdateret',
                  type: 'success'
                });
              }
          }).catch(error => {
              this.$message({
                  message: 'Vi kunne ikke opdatere dit kodeord. Prøv venligst igen.',
                  type: 'error'
                });
                console.log(error);
          })
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validatePassword(password) {
        const re = /\d/;
        return re.test(password);
    },
    updateUserInfo(userDataToUpdate)
    {
      axios.post(`${process.env.VUE_APP_BACKEND_URL}Auth/UpdateUserInfo`, userDataToUpdate)
          .then(response => {
              var data = response.data; 
              if(data.success)
              {
                axios.get(`${process.env.VUE_APP_BACKEND_URL}Auth/GetCurrentUserInfo`, )
                .then(response => {
                  localStorage.setItem("user", JSON.stringify(response.data.data));
                });

                this.$message({
                  message: 'Dine oplysninger er blevet gemt',
                  type: 'success'
                });
              }
          }).catch(error => {
              this.$message({
                  message: 'Vi kunne ikke opdatere dine oplysninger. Prøv venligst igen.',
                  type: 'error'
                });
                console.log(error);
          })
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@media only screen and (min-width: 768px) {
    /* For mobile phones: */
    .account-page {
      border-top: 1px solid rgb(204 204 204);
      margin-top: 80px;
    }
}
@media only screen and (max-width: 768px) {
  .receivedMessage, .rejectionMessage {
    margin-top: 20px;
  }
}

.account-page {
  width: 100%;
  max-width: 1488px;
  margin: 0 auto;
  margin-top: 80px;
}

/* My details */

.my-details-container {
  width: 100%;
}

.input-error {
    float: left;
    width: 100%;
    font-size: 14px;
    color: rgb(255, 85, 85);
}

.divider {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.3);
}

.section-row {
  padding-bottom: 20px !important;;
}

.section-header {
  font-size: 18px;
  font-weight: 600;
}

.second-section-header {
  margin-top: 50px;
}

.section-description {
  line-height: 30px;
  margin-top: 20px;
}

.section-inputs {
  margin-top: 15px;
}

.section-inputs div {
  margin-top: 20px;
}

.passwords div:nth-child(2) {
  margin-top: 40px !important;
}

.save-changes {
  margin-top: 20px;
}

/* Bookings */

.booking-card, .booking-card-finished {
  margin-top: 20px;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 10px;
  background-color: white;
}

.booking-card .el-row {
  margin-top: 10px;
  padding-bottom: 10px;
}

.booking-card-finished .el-row {
  padding-bottom: 12px;
}

.booking-card-image {
  margin-top: 10px;
  width: 100%;
  object-fit: contain;
  float: left;
}

.booking-card-description {
  float: left;
  margin-left: 20px;
}

.booking-card-title {
  font-size: 18px;
  font-weight: 500;
  float: left;
  height: 20px; 
  width: 100%;   
}

.booking-card-region-and-city {
  height: 18px;
  float: left;
  width: 100%;
}

.booking-card-address {
  float: left;
  width: 100%;
    
}

.booking-card-finished-weekcount {
  margin-top: 20px;
  float: right;
  width: 100%;
}

.booking-card-weekcount {
  float: left;
  width: 100%;
  font-weight: 600;
}

.booking-card-actions {
  padding-top: 45px;
}

.booking-card-finished-actions {
  padding-top: 15px;
}

.rejection-message {
  padding: 5px;
  background-color: #f5f3f3;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
}

.send-to-activation-btn {
  padding-top: 10px;
}

.send-to-activation-btn:hover, .add-media-btn:hover, .reorder-btn:hover{
    background-color: rgb(255, 255, 255);
    border-color: #9433ff;
    color: #9433ff;
}

.add-media-description {
  word-break: break-word;
}

.add-media-actions {
  padding-bottom: 30px;
}

.upload-box {
  margin-top: 20px;
}

/* Invoice */
.invoice-container {
  margin-top: 10px;
  padding: 10px 10px 5px 10px;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 10px;
  background-color: white;
  line-height: 60px;
}

.font-weight-600 {
  font-weight: 600;
}

.el-input-group__append, .el-input-group__prepend {
    text-align: center;
    width: 120px !important;
}

</style>